<template>
  <div>
    <Header :curColid="currentParentColId"></Header>
    <div class="wrap layout">
      <div class="pageDiv">
        <div class="page">
          <span @click="goindex">首页></span>
          <span>重点项目</span>
        </div>
      </div>
      <div class="pageContent clear">
        <div class="search-box">
          <input
            type="text"
            v-model="searchTitle"
            maxlength="255"
            placeholder="请输入标题搜索"
          />
          <button id="search" @click="search">搜索</button>
        </div>
        <div class="screen">
          <div class="title">重点项目筛选</div>
          <div class="screenItem" v-for="(item, index) in columns" :key="index">
            <h3>{{ item.name }}：</h3>
            <div class="screenList">
              <a :class="item.selected ? 'on' : ''" @click="all(index)">
                全部
              </a>
              <a
                v-for="(item1, index1) in item.children"
                :key="index1"
                href="javascript:;"
                :class="item1.selected ? 'on' : ''"
                @click="tab(index, index1)"
              >
                {{ item1.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="projectItem">
          <div class="projectDiv">
            <div
              class="projectList wow fadeInUpBig animated"
              data-wow-delay=".2s"
              v-for="(item, index) in projectList"
              :key="index"
              @click="goAricle(item, 5)"
            >
              <p>{{ item.title }}</p>
              <div class="tag" v-if="item.source">
                <span>{{ item.source }}</span>
              </div>
              <div v-else class="tag">
                <span style="background: white; color: white"> 暂无 </span>
              </div>
              <font> 摘要：{{ item.summary }} </font>
              <a href="">查看详情</a>
              <i class="icon11" v-if="item.isTop"></i>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 1200px; margin: 30px auto">
        <el-pagination
          layout="total,prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="20"
          :total="total"
          :current-page="page"
          style="float: right"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import api from "../url/url";
import Header from "../components/Header.vue";

export default {
  name: "zdxmA",
  components: {
    Header: Header,
  },
  data() {
    return {
      searchTitle: "",
      tabIndex: 0,
      columns: [],
      page: 1,
      total: 0,
      currentParentColId: 0,
      projectList: [],
      classArr: [],
    };
  },
  methods: {
    goindex() {
      this.$router.push("/");
    },
    goAricle(item, columnId) {
      this.$router.push({
        name: "newsPagee",
        query: {
          id: item.id,
          name: item.title,
          columnId: columnId,
          columnsName: "重点项目推荐",
        },
      });
    },
    getProjectList() {
      //調用接口
      axios
        .post(`${api}/v1/article/search/class?pi=${this.page}&ps=20`, {
          colid: 5,
          classArr: this.classArr,
        })
        .then((res) => {
          this.projectList = res.data.list;
          this.total = res.data.total;
        });
    },
    dealSelectData() {
      this.classArr = [];
      this.columns.forEach((element) => {
        if (element.selected) {
          //取子集所有id
          element.children.forEach((e) => {
            this.classArr.push(e.id);
          });
        } else {
          //查找子集選中的id
          element.children.forEach((e) => {
            if (e.selected) this.classArr.push(e.id);
          });
        }
      });
      this.getProjectList();
    },
    all(pindex) {
      this.columns[pindex].selected = true;
      this.columns[pindex].children.forEach((element) => {
        element.selected = false;
      });
      this.dealSelectData();
    },
    tab(pindex, cindex) {
      this.searchTitle = "";
      let selectitem = this.columns[pindex].children.filter((x) => x.selected);
      if (selectitem.length > 0) {
        if (this.columns[pindex].children[cindex].id != selectitem[0].id) {
          this.columns[pindex].children.forEach((element) => {
            element.selected = false;
          });
          this.columns[pindex].children[cindex].selected = true;
        }
      } else {
        this.columns[pindex].children[cindex].selected = true;
      }
      this.columns[pindex].selected = false;
      this.dealSelectData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getProjectList();
    },
    getColumns() {
      axios.get(`${api}/v1/classify/columns/5`).then((res) => {
        let arr = res.data.filter((x) => x.parentId == 0);
        arr.forEach((element) => {
          let arr1 = res.data.filter((x) => {
            if (x.parentId == element.id) {
              x.selected = false;
              return x;
            }
          });
          element.children = arr1;
          element.selected = true;
          this.columns.push(element);
        });
        this.columns.forEach((item) => {
          item.children.forEach((item1) => {
            this.classArr.push(item1.id);
          });
        });
        this.getProjectList();
      });
    },
    getSearch() {
      axios
        .get(`${api}/v1/article/search/name/5?q=${this.searchTitle}`)
        .then((res) => {
          this.projectList = res.data.list;
          this.total = res.data.list.length;
        });
    },
    search() {
      if (this.searchTitle) {
        this.columns.forEach((item) => {
          item.selected = true;
          item.children.forEach((item1) => {
            item1.selected = false;
          });
        });
        this.getSearch();
      }
    },
  },
  mounted() {
    this.currentParentColId = this.$route.query.cpid; //父栏目id
    this.getColumns();
  },
};
</script>
  <style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>