<template>
    <div>
        <Header :curColid="currentParentColId"></Header>
        <div>
            <div class="wrap layout">
                <div class="pageDiv">
                    <div class="page">
                        <span @click="goindex">首页></span>
                        <span>孵化项目</span>
                    </div>
                </div>
                <div class="pageContent clear">
                    <div class="fhxmList" v-for="item in columns" :key="item.id">
                        <h3>{{ item.title }}</h3>
                        <div class="list">
                            <a v-for="childItem in item.child" :key="childItem.id" @click="goNewsPage(childItem.id,4)">{{childItem.title}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "../url/url";
import Header from "../components/Header.vue";

export default {
    name: "fhxmA",
    components: {
        Header: Header,
    },
    data() {
        return {
            columnsName: "",
            columns: [],
            active: 0,
            articles: [],
            currentParentColId: 0,
            content: {},
            total: 0,
            page: 1,
        };
    },
    methods: {
        goindex() {
            this.$router.push("/");
        },
        goNewsPage(id, columnId) {
            this.$router.push({
                name: "newsPagee",
                query: {
                    id: id,
                    columnId: columnId,
                },
            });
        },
        onclick(id, columnsName) {
            this.columnsName = columnsName;
            this.active = id;
            this.getArticles(id);
        },
        getColumns() {
            axios
                .get(`${api}/v1/columns/parent/4?`, {
                    params: {
                        pi: this.page,
                        ps: 20,
                    },
                })
                .then((res) => {
                    res.data.list.forEach((element) => {
                        axios
                            .get(`${api}/v1/article/${element.id}/0`, {
                                params: {
                                    pi: this.page,
                                    ps: 200,
                                },
                            })
                            .then((res1) => {
                                element.child = res1.data.list;
                                this.columns.push(element);
                            });
                    });
                });
        },
        handleCurrentChange(val) {
            this.page = val;
        },
    },
    mounted() {
        this.currentParentColId = this.$route.query.cpid; //父栏目id
        this.getColumns();
    },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>