<template>
  <div>
    <Header :curColid="currentParentColId"></Header>
    <div class="wrap layout">
      <div class="pageDiv">
        <div class="page">
          <span @click="goindex">首页></span>
          <span>新闻资讯</span>
        </div>
      </div>
      <div class="pageContent clear">
        <div class="left">
          <div
            class="list2"
            v-for="item in columns"
            :key="item.id"
            @click="onclick(item.id, item.title)"
          >
            <a :class="currentColId == item.id ? 'on' : ''"
              >{{ item.title }}
              <!-- :class="item.id?'on':''" -->
            </a>
          </div>
        </div>
        <div class="newsRight">
          <h3>{{ columnsName }}</h3>
          <!-- 标题点击变换 -->
          <div class="list">
            <!-- <div >
                            <a>
                                <p>{{ item.title }}</p>
                                <i>{{ item.create_time|dateTimeFormatter }}</i>
                            </a>
                        </div> -->

            <div
              class="newList"
              v-for="item in articles"
              :key="item.id"
              @click="goNewsPage(item.id, 2)"
            >
              <div class="img">
                <img :src="imgURL + item.coverImg" />
              </div>
              <div class="txt">
                <p>{{ item.title }}</p>
                <div class="label">
                  <span class="cyan">{{ columnsName }}</span>
                  <b>{{ item.create_time | dateTimeFormatter }}</b>
                </div>
                <font>
                  {{ item.summary }}
                  <!-- 11月2日，公司党委副书记曹世正为新发展党员、入党积极分子及党员发展对象讲授了主题为《中国式现代化》专题党课。曹世正围绕“中国式现代化的理论含义”、“中国式现代化的基本特征”两个论点展开，通过生动事例... -->
                </font>
              </div>
            </div>
          </div>
          <div>
            <el-pagination
              layout="total,prev, pager, next"
              @current-change="handleCurrentChange"
              :page-size="20"
              :total="total"
              :current-page="page"
              style="float: right"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import api from "../url/url";

export default {
  name: "newA",
  components: {
    Header: Header,
  },
  data() {
    return {
      imgURL: api + "/assets/",
      columnsName: "",
      columns: [],
      currentColId: 0,
      currentParentColId: 0,
      articles: [],
      content: {},
      total: 0,
      page: 1,
    };
  },

  methods: {
    goindex() {
      this.$router.push("/");
    },
    goNewsPage(id, columnId) {
      this.$router.push({
        name: "newsPagee",
        query: {
          id: id,
          columnId: columnId,
          navId: this.currentColId,
          columnsName: this.columnsName,
        },
      });
    },
    onclick(id, columnsName) {
      this.columnsName = columnsName;
      this.currentColId = id;
      this.getArticles();
    },
    getColumns() {
      axios
        .get(`${api}/v1/columns/parent/${this.currentParentColId}`, {
          params: {
            pi: this.page,
            ps: 20,
          },
        })
        .then((res) => {
          this.columns = res.data.list;
          if (this.columns.length > 0) {
            if (this.currentColId == 0) {
              //默认
              this.columnsName = this.columns[0].title;
              this.currentColId = this.columns[0].id;
            } else {
              //指定栏目
              this.columnsName = this.columns.filter(
                (x) => x.id == this.currentColId
              )[0].title;
            }
            this.getArticles();
          }
        });
    },

    getArticles() {
      axios
        .get(`${api}/v1/article/${this.currentColId}/0`, {
          params: {
            pi: this.page,
            ps: 20,
          },
        })
        .then((res) => {
          this.content = res.data.list[0];
          this.total = res.data.total;
          this.articles = res.data.list;
          this.articles.forEach((item) => {
            let str = item.coverImg.split("/");
            if (this.currentColId == 10) {
              item.coverImg =
                str[0] + "/" + str[1] + "/" + str[2] + "/" + "_800_" + str[3];
            } else {
              item.coverImg =
                str[0] + "/" + str[1] + "/" + str[2] + "/" + "_300_" + str[3];
            }
          });
        });
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getArticles();
    },
  },
  mounted() {
    this.currentParentColId = this.$route.query.cpid; //父栏目id
    this.currentColId = this.$route.query.clid; //子栏目id
    this.getColumns();
  },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>