<template>
    <div class="header clear">
        <div class="box">
            <div class="logo">
                <h1>太原理工技术转移有限公司</h1>
                <a href="/">
                    <img src="../assets/images/logo.png" />
                </a>
            </div>
            <div class="menu_page">
                <ul>
                    <li v-for="item in colmunList" :key="item.id" :class="'main_page'+(curColid==item.id?' on':'')" @mouseover="isShowId=item.id" @mouseleave="isShowId=0">
                        <a :href="item.id?item.href+'?cpid='+item.id+'&clid=0':item.href" class="main_a"> {{item.name}} </a>
                        <!-- <div v-show="isShowId==item.id">
                            <a :href="item.href+'?cpid='+item.id+'&clid='+childItem.id" v-for="childItem in item.child" :key="childItem.id">{{childItem.title}}</a>
                        </div> -->
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["curColid"],
    name: "HeaderA",
    data() {
        return {
            isShowId: 0,
            colmunList: [
                { id: 0, name: "首页", href: "/" },
                { id: 1, name: "公司简介", href: "intro" },
                {
                    id: 2,
                    name: "新闻资讯",
                    href: "newss",
                    child: [],
                },
                { id: 3, name: "科技成果", href: "techAchievee" },
                { id: 4, name: "在孵企业", href: "enterprisess" },
                { id: 5, name: "重点项目推荐", href: "keyProjectt" },
                { id: 6, name: "专家库", href: "expertt" },
                {
                    id: 7,
                    name: "技术服务",
                    href: "servicess",
                    child: [],
                },
                { id: 8, name: "下载中心", href: "downn" },
            ],
        };
    },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>