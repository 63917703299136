<template>
  <div>
    <Header></Header>
    <div class="wrap layout">
      <div class="pageDiv">
        <div class="page">
          <span @click="goindex">首页></span>
          <span v-if="columnId == 2" @click="goColumn(2)">新闻资讯></span>
          <span v-if="columnId == 7" @click="goColumn(7)">技术服务></span>
          <span v-if="columnId == 3" @click="goColumn(3)">科技成果></span>
          <span v-if="columnId == 5" @click="goColumn(5)">重点项目></span>
          <span v-if="columnId == 4" @click="goColumn(4)">孵化项目></span>
          <span>详情</span>
        </div>
      </div>

      <section class="pageBox">
        <div class="newsContent">
          <div class="title">
            {{ content.title }}
          </div>
          <div class="date">
            <span>来源：{{ content.source }}</span>
            <!-- 时间转换 -->
            <span>时间： {{ content.pubdate | dateTimeFormatter }}</span>
          </div>
          <div class="desc" v-if="content.summary">
            {{ content.summary }}
          </div>
          <div class="editText" v-html="content.content"></div>
        </div>

        <!-- 右侧 -->
        <div class="rightNav" v-if="columnId == 3">
          <!-- 1 -->
          <div class="navList">
            <div class="navTitle">
              <h3>{{ columnsName }}</h3>
            </div>
            <div>
              <span
                v-for="(item, index) in navList"
                :key="index"
                @click="tabList(item)"
                >{{ item.title }}</span
              >
            </div>
            <div class="pages">
              <el-pagination
                layout="total,prev, pager, next"
                @current-change="handleCurrentChange"
                :page-size="7"
                :total="total"
                :current-page="page"
                style="float: right"
              ></el-pagination>
            </div>
          </div>
        </div>

        <div class="rightNav" v-if="columnId == 2">
          <!-- 1 -->
          <div class="navList">
            <div class="navTitle">
              <h3
                :class="newTabs == 1 ? 'on' : ''"
                @click="newTab(1, 10)"
                style="cursor: pointer"
              >
                {{ "新闻资讯" }}
              </h3>
              <h3
                :class="newTabs == 2 ? 'on' : ''"
                @click="newTab(2, 9)"
                style="cursor: pointer"
              >
                {{ "最新政策" }}
              </h3>
            </div>

            <div>
              <span
                v-for="(item, index) in news.list"
                :key="index"
                @click="tabList(item)"
                >{{ item.title }}</span
              >
            </div>
            <div class="pages">
              <el-pagination
                layout="total,prev, pager, next"
                @current-change="handleNewsCurrentChange"
                :page-size="5"
                :total="news.total"
                :current-page="news.page"
                style="float: right"
              ></el-pagination>
            </div>
          </div>
          <!-- 2 -->
          <div class="navList">
            <div class="navTitle">
              <h3>{{ project.columnName }}</h3>
            </div>
            <div>
              <span
                v-for="(item, index) in project.list"
                :key="index"
                @click="tabList(item)"
                >{{ item.title }}</span
              >
            </div>
            <div class="pages">
              <el-pagination
                layout="total,prev, pager, next"
                @current-change="handleProjectCurrentChange"
                :page-size="5"
                :total="project.total"
                :current-page="project.page"
                style="float: right"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="rightNav" v-if="columnId == 5">
          <!-- 1 -->
          <div class="navList">
            <div class="navTitle">
              <h3>{{ project.columnName }}</h3>
            </div>
            <div>
              <span
                v-for="(item, index) in project.list"
                :key="index"
                @click="tabList(item)"
                >{{ item.title }}</span
              >
            </div>
            <div class="pages">
              <el-pagination
                layout="total,prev, pager, next"
                @current-change="handleProjectCurrentChange"
                :page-size="5"
                :total="project.total"
                :current-page="project.page"
                style="float: right"
              ></el-pagination>
            </div>
          </div>
          <!-- 2 -->
          <div class="navList">
            <div class="navTitle">
              <h3
                :class="newTabs == 1 ? 'on' : ''"
                @click="newTab(1, 10)"
                style="cursor: pointer"
              >
                {{ "新闻资讯" }}
              </h3>
              <h3
                :class="newTabs == 2 ? 'on' : ''"
                @click="newTab(2, 9)"
                style="cursor: pointer"
              >
                {{ "最新政策" }}
              </h3>
            </div>
            <div>
              <span
                v-for="(item, index) in news.list"
                :key="index"
                @click="tabList(item)"
                >{{ item.title }}</span
              >
            </div>
            <div class="pages">
              <el-pagination
                layout="total,prev, pager, next"
                @current-change="handleNewsCurrentChange"
                :page-size="5"
                :total="news.total"
                :current-page="news.page"
                style="float: right"
              ></el-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
import api from "../url/url";

export default {
  components: {
    Header: Header,
  },
  data() {
    return {
      content: {},
      columnId: 0,
      columnsName: "",
      navId: 0,
      navList: [],
      page: 1,
      total: 0,
      searchTitle: "",
      news: {
        columnName: "",
        navId: 0,
        page: 1,
        total: 0,
        list: [],
      },
      project: {
        columnName: "",
        navId: 0,
        page: 1,
        total: 0,
        list: [],
      },
      columns: [],
      classArr: [],
      newTabs: 1,
      newId: 10,
    };
  },

  methods: {
    getArticles(id) {
      axios

        .get(`${api}/v1/article/${id}/0`, {
          params: {
            pi: this.page,
            ps: 7,
            q: this.searchTitle,
          },
        })
        .then((res) => {
          this.total = res.data.total;
          this.navList = res.data.list;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      if (this.$route.query.routeName == "tech") {
        this.getArticles(this.navId);
      } else {
        this.getResult();
      }
    },
    getResult() {
      axios
        .get(`${api}/v1/article/index/results/3`, {
          params: {
            pi: this.page,
            ps: 7,
          },
        })
        .then((res) => {
          this.total = res.data.list.length;
          this.navList = res.data.list;
        });
    },
    newTab(index, id) {
      this.newTabs = index;
      this.newId = id;
      this.news.page = 1;
      this.getNewsArticles(id);
    },
    //获取新闻
    getNewsArticles(id) {
      axios
        .get(`${api}/v1/article/${id}/0`, {
          params: {
            pi: this.news.page,
            ps: 5,
          },
        })
        .then((res) => {
          this.news.total = res.data.total;
          this.news.list = res.data.list;
        });
    },
    //获取重点项目
    getColumns() {
      axios.get(`${api}/v1/classify/columns/5`).then((res) => {
        let arr = res.data.filter((x) => x.parentId == 0);
        arr.forEach((element) => {
          let arr1 = res.data.filter((x) => {
            if (x.parentId == element.id) {
              x.selected = false;
              return x;
            }
          });
          element.children = arr1;
          element.selected = true;
          this.columns.push(element);
        });
        this.columns.forEach((item) => {
          item.children.forEach((item1) => {
            this.classArr.push(item1.id);
          });
        });
        this.getProjectList();
      });
    },
    getProjectList() {
      //調用接口
      axios
        .post(`${api}/v1/article/search/class?pi=${this.project.page}&ps=5`, {
          colid: 5,
          classArr: this.classArr,
        })
        .then((res) => {
          this.project.list = res.data.list;
          this.project.total = res.data.total;
        });
    },
    handleNewsCurrentChange(val) {
      this.news.page = val;
      this.getNewsArticles(this.newId);
    },
    handleProjectCurrentChange(val) {
      this.project.page = val;
      this.getProjectList();
    },
    tabList(item) {
      this.getList(item.id);
    },
    async getList(id) {
      await axios.get(`${api}/v1/article/${id}`).then((res) => {
        this.content = res.data;
      });
    },
    goindex() {
      this.$router.push("/");
    },
    goColumn(item) {
      if (item == 2) {
        this.$router.push({
          name: "newss",
          query: { cpid: 2, clid: 0 },
        });
      } else if (item == 7) {
        this.$router.push({
          name: "servicess",
          query: {
            cpid: 7,
            clid: 0,
          },
        });
      } else if (item == 3) {
        this.$router.push({
          name: "techAchievee",
          query: {
            cpid: 3,
            clid: 0,
          },
        });
      } else if (item == 5) {
        this.$router.push({
          name: "keyProjectt",
          query: {
            cpid: 5,
            clid: 0,
          },
        });
      } else if (item == 4) {
        this.$router.push({
          name: "enterprisess",
          query: {
            cpid: 4,
            clid: 0,
          },
        });
      }
    },
  },
  mounted() {
    this.newTabs = 1;
    this.columnId = this.$route.query.columnId;
    if (this.columnId == 3) {
      if (this.$route.query.routeName == "tech") {
        this.columnsName = this.$route.query.columnsName;
        this.navId = this.$route.query.navId;
        this.getArticles(this.navId);
      } else {
        this.columnsName = "成果展示";
        this.getResult();
      }
    } else if (this.columnId == 2) {
      this.getNewsArticles(10);
      this.project.columnName = "重点项目推荐";
      this.getColumns();
    } else if (this.columnId == 5) {
      this.project.columnName = "重点项目推荐";
      this.getColumns();
      this.getNewsArticles(10);
    }
    this.getList(this.$route.query.id);
  },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>