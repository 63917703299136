<template>
  <div>
    <Header :curColid="currentParentColId"></Header>
    <div class="wrap layout">
      <div class="pageDiv">
        <div class="page">
          <span @click="goindex">首页></span>
          <span>科技成果</span>
        </div>
      </div>
      <div class="pageContent clear">
        <div class="left">
          <h3>科技成果</h3>
          <div
            :class="item.id == active ? 'list active' : 'list'"
            v-for="item in columns"
            :key="item.id"
            @click="onclick(item.id, item.title)"
          >
            <a>{{ item.title }}</a>
          </div>
        </div>
        <div class="right">
          <div class="rightTop">
            <h3>{{ columnsName }}</h3>
            <div class="search-box">
              <input
                type="text"
                v-model="searchTitle"
                maxlength="255"
                placeholder="请输入标题搜索"
              />
              <button id="search" @click="search">搜索</button>
            </div>
          </div>

          <div class="list">
            <div
              v-for="item in articles"
              :key="item.id"
              @click="goNewsPage(item.id, 3)"
            >
              <a href="#/newsPagee">
                <p>{{ item.title }}</p>
                <font>{{ item.summary }}</font>
              </a>
            </div>
          </div>
          <div>
            <el-pagination
              layout="total,prev, pager, next"
              @current-change="handleCurrentChange"
              :page-size="20"
              :total="total"
              :current-page="page"
              style="float: right"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "../url/url";

import Header from "../components/Header.vue";

export default {
  name: "kjcgA",
  components: {
    Header: Header,
  },
  data() {
    return {
      searchTitle: "",
      message: "cpid",
      active: 0,
      currentParentColId: 0,
      columnsName: "",
      columns: [],
      articles: [],
      content: {},
      total: 0,
      page: 1,
    };
  },
  methods: {
    search() {
      this.getArticles(this.active);
    },
    goindex() {
      this.$router.push("/");
    },
    goNewsPage(id, columnId) {
      this.$router.push({
        name: "newsPagee",
        query: {
          id: id,
          columnId: columnId,
          columnsName: this.columnsName,
          navId: this.active,
          routeName: "tech",
        },
      });
    },
    onclick(id, columnsName) {
      this.searchTitle = "";
      this.columnsName = columnsName;
      this.getArticles(id);
      this.active = id;
    },

    getColumns() {
      axios

        .get(`${api}/v1/columns/parent/3`, {
          params: {
            pi: this.page,
            ps: 30,
          },
        })
        .then((res) => {
          this.columns = res.data.list;
          if (this.columns.length > 0) {
            this.columnsName = this.columns[0].title;
            this.active = this.columns[0].id;
            this.getArticles(this.columns[0].id);
          }
        });
    },
    getArticles(id) {
      axios

        .get(`${api}/v1/article/${id}/0`, {
          params: {
            pi: this.page,
            ps: 20,
            q: this.searchTitle,
          },
        })
        .then((res) => {
          this.content = res.data.list[0];
          this.total = res.data.total;
          this.articles = res.data.list;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getArticles(this.active);
    },
  },
  mounted() {
    this.currentParentColId = this.$route.query.cpid; //父栏目id

    this.getColumns();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/page.scss";
.active {
  a {
    color: #0142a4 !important;
  }
}
</style>
