import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import filter from "../src/filter/filter"

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
// 全局挂载
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key]);
})
Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')

