<template>
    <div>
        <Header :curColid="currentParentColId"></Header>
        <div>
            <div class="wrap layout">
                <div class="pageDiv">
                    <div class="page">
                        <span @click="goindex">首页></span>
                        <span>下载中心</span>
                    </div>
                </div>
                <div class="pageContent clear">
                    <div class="fhxmList">
                        <h3>下载列表</h3>
                        <div class="list3">
                            <a :href="api+'/assets/' + item.path" v-for="item in filesList" :key="item.id">{{ item.title }}</a>
                            <div>
                                <el-pagination layout="total,prev, pager, next" @current-change="handleCurrentChange" :page-size="20" :total="total" :current-page="page"
                                    style="float: right"></el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "../url/url";
import Header from "../components/Header.vue";
export default {
    name: "downA",
    components: {
        Header: Header,
    },
    data() {
        return {
            filesList: [],
            total: 0,
            page: 1,
            api: api,
            currentParentColId: 0,
        };
    },
    methods: {
        goindex() {
            this.$router.push("/");
        },
        getFileList() {
            axios
                .get(`${api}/v1/fileDownload?`, {
                    params: {
                        pi: this.page,
                        ps: 20,
                    },
                })
                .then((res) => {
                    this.filesList = res.data.list;
                    this.total = res.data.total;
                });
        },
        handleCurrentChange(val) {
            this.page = val;
        },
    },
    mounted() {
        this.currentParentColId = this.$route.query.cpid; //父栏目id
        this.getFileList();
    },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>