<template>
  <div id="app">
    <Header></Header>
    <div class="wrap layout">
      <div class="pageContent clear">
        <div class="form">
          <div class="formTitle">
            <h3>维权工作站简介</h3>
            <h4>
              {{ ntor }}
            </h4>
          </div>
          <div class="formList">
            <div class="formInput">
              <label>维权人姓名：</label>
              <input
                v-model="form.name"
                type="text"
                placeholder="输入维权人姓名"
              />
            </div>
            <div class="formInput">
              <label>案件证据：</label>
              <textarea
                v-model="form.proof"
                placeholder="申请援助事项的基本情况以及有关的案情材料"
              ></textarea>
            </div>
            <div class="formInput">
              <label>联系人：</label>
              <input
                v-model="form.linkMan"
                type="text"
                placeholder="输入联系人"
              />
            </div>
            <div class="formInput">
              <label>联系方式：</label>
              <input
                v-model="form.linkWay"
                type="text"
                placeholder="输入联系方式"
              />
            </div>
            <div class="formInput">
              <label>联系人邮箱：</label>
              <input
                v-model="form.email"
                type="text"
                placeholder="输入联系人邮箱"
              />
            </div>
            <div class="formInput">
              <label>备注：</label>
              <textarea v-model="form.remark" placeholder="备注内容"></textarea>
            </div>
            <div class="formInput formInputRadio">
              <label style="width: 200px">该需求是否愿意对外公开：</label>
              <div>
                <label>
                  <input
                    v-model="form.isOpen"
                    type="radio"
                    :checked="form.isOpen ? 'checked' : ''"
                    :value="true"
                    name="tag"
                  />
                  <span>是</span>
                </label>
                <label>
                  <input
                    v-model="form.isOpen"
                    type="radio"
                    :checked="form.isOpen ? 'checked' : ''"
                    :value="false"
                    name="tag"
                  />
                  <span>否</span>
                </label>
              </div>
            </div>
            <button @click="submit">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
import api from "../url/url";

export default {
  name: "mendA",
  components: {
    Header: Header,
  },
  data() {
    return {
      ntor: "",
      form: {
        name: "",
        proof: "",
        linkMan: "",
        linkWay: "",
        email: "",
        remark: "",
        isOpen: true,
      },
    };
  },
  methods: {
    //获取描述内容
    getContent() {
      axios.get(`${api}/v1/indexDescribe/2`).then((res) => {
        this.ntor = res.data.ntro;
      });
    },
    submit() {
      if (!this.form.name) {
        this.$message.error("请输入维权人姓名");
      } else {
        axios.post(`${api}/v1/maintain`, this.form).then((res) => {
          console.log(res);
          if (res.data.code == 11132) {
            this.$message.success(`${res.data.message}`);
            this.form = {
              name: "",
              proof: "",
              linkMan: "",
              linkWay: "",
              email: "",
              remark: "",
              isOpen: true,
            };
          }
        });
      }
    },
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>