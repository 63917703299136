<template>
  <div class="wrap footer">
    <div class="footerCon">
      <div class="left">
        <div class="logo"><img src="../assets/images/logo2.png" /></div>
        <div class="leftInfo">
          <i class="icon1"></i>
          <font
            >太原市万柏林区西矿街53号山西太原理工资产经营管理有限公司1层</font
          >
        </div>
        <div class="leftInfo">
          <i class="icon2"></i>
          <font>19834438565/18503431722</font>
        </div>
        <div class="leftInfo">
          <i class="icon3"></i>
          <font>tylgdxfhy@163.com</font>
        </div>
      </div>
      <div class="leftLink">
        <div class="linkList">
          <h3>友情链接</h3>
          <div>
            <a href="https://jyt.shanxi.gov.cn/">山西省教育厅</a>
            <a href="https://fgw.shanxi.gov.cn/">山西省发展和改革委员会</a>
            <a href="https://kjt.shanxi.gov.cn/">山西省科学技术厅</a>
            <a href="https://www.tyut.edu.cn/">太原理工大学</a>
            <a href="https://gxt.shanxi.gov.cn/">山西省工业和信息化厅</a>
            <a href="https://kj.tyut.edu.cn/">太原理工大学科学技术研究院</a>
          </div>
        </div>
      </div>
      <div class="code">
        <img src="../assets/images/code.jpg" />
        <font>技术转移网公众号</font>
      </div>
    </div>
    <div class="common_copy">
      <p><a href="https://beian.miit.gov.cn">晋ICP备2023017533号-1</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

</style>