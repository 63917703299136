<template>
    <div>
        <Header></Header>
        <div class="wrap layout">
            <div class="pageDiv">
                <div class="page">
                    <span @click="goindex">首页></span>
                    <span @click="goColumn(6)">专家库></span>
                    <span>详情</span>
                </div>
            </div>
            <div class="pageContent clear">
                <div class="zdxmPage">
                    <div class="leftPage">
                        <img :src="imgURL + expertData.headImg" class="leftImg2" />
                    </div>
                    <div class="rightPage">
                        <div class="title">{{ expertData.name }}</div>
                        <div class="pageList">
                            <span v-for="(item, index) in expertData.position" :key="index" style="margin-right: 5px">{{ item.tagName }}</span>
                            <span>擅长领域：<font v-for="(item, index) in expertData.goodAt" :key="index" style="margin-right: 5px">{{ item.tagName }}</font></span>
                            <span v-if="expertData.qrCode"><img :src="imgURL+expertData.qrCode"></span>
                        </div>
                        <div class="line"></div>
                        <div class="text">
                            <div class="title2"><i></i>详细介绍</div>
                            <div v-html="expertData.introduce"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
import Header from "../components/Header.vue";
import axios from "axios";
import api from "../url/url";

export default {
    name: "experttA",
    components: {
        Header: Header,
    },
    data() {
        return {
            imgURL: `${api}/assets/`, //图片地址
            expertData: {},
        };
    },
    methods: {
        goindex() {
            this.$router.push("/");
        },
        goColumn(item) {
            if (item == 6) {
                this.$router.push({
                    name: "expertt",
                    query: { cpid: 6, clid: 0 },
                });
            }
        },
        getExpertDetails() {
            axios
                .get(`${api}/v1/professor/${this.$route.query.id}`)
                .then((res) => {
                    this.expertData = res.data;
                });
        },
    },
    mounted() {
        this.columnId = this.$route.query.columnId;
        this.getExpertDetails();
    },
};
</script>
  
  <style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>