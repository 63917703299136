<template>
  <div id="app">
    <Header></Header>
    <div class="wrap layout">
      <div class="pageContent clear">
        <div class="form">
          <div class="formTitle">
            <h3>基本信息</h3>
            <h4>
              {{ ntor }}
            </h4>
          </div>
          <div class="formList">
            <div class="formInput">
              <label>公司名称：</label>
              <input
                v-model="form.corpName"
                type="text"
                placeholder="输入公司名称"
              />
            </div>
            <div class="formInput">
              <label>公司简介：</label>
              <textarea
                v-model="form.ntro"
                placeholder="请输入公司简介"
              ></textarea>
            </div>
            <div class="formInput">
              <label>需求：</label>
              <textarea
                v-model="form.needDescribe"
                placeholder="请输入您的需求（技术、人才、其他）"
              ></textarea>
            </div>
            <div class="formInput">
              <label>联系人：</label>
              <input
                v-model="form.linkMan"
                type="text"
                placeholder="输入联系人"
              />
            </div>
            <div class="formInput">
              <label>联系方式：</label>
              <input
                v-model="form.linkWay"
                type="text"
                placeholder="输入联系方式"
              />
            </div>
            <div class="formInput">
              <label>备注：</label>
              <textarea v-model="form.remark" placeholder="备注内容"></textarea>
            </div>
            <div class="formInput formInputRadio">
              <label style="width: 200px">该需求是否愿意对外公开：</label>
              <div>
                <label>
                  <input
                    v-model="form.isOpen"
                    type="radio"
                    :checked="form.isOpen ? 'checked' : ''"
                    :value="true"
                    name="tag"
                  />
                  <span>是</span>
                </label>
                <label>
                  <input
                    v-model="form.isOpen"
                    type="radio"
                    :checked="form.isOpen ? 'checked' : ''"
                    :value="false"
                    name="tag"
                  />
                  <span>否</span>
                </label>
              </div>
            </div>
            <button @click="submit">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
import api from "../url/url";

export default {
  name: "mendA",
  components: {
    Header: Header,
  },
  data() {
    return {
      ntor: "",
      form: {
        corpName: "",
        ntro: "",
        needDescribe: "",
        linkMan: "",
        linkWay: "",
        remark: "",
        isOpen: true,
      },
    };
  },
  methods: {
    //获取描述内容
    getContent() {
      axios.get(`${api}/v1/indexDescribe/1`).then((res) => {
        this.ntor = res.data.ntro;
      });
    },
    submit() {
      if (!this.form.corpName) {
        this.$message.error("请输入公司名称");
      } else if (!this.form.needDescribe) {
        this.$message.error("请输入您的需求");
      } else {
        axios.post(`${api}/v1/requirement`, this.form).then((res) => {
          if (res.data.code == 11131) {
            this.$message.success(`${res.data.message}`);
            this.form = {
              corpName: "",
              ntro: "",
              needDescribe: "",
              linkMan: "",
              linkWay: "",
              remark: "",
              isOpen: true,
            };
          }
        });
      }
    },
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/page.scss";
</style>
