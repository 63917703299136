<template>
    <div>
        <Header :curColid="currentParentColId"></Header>
        <div class="newsContent">
            <div class="title">{{ content.title }}</div>
            <div class="editText">
                <div v-html="content.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "../url/url";
import Header from "../components/Header.vue";

export default {
    name: "aboutA",
    components: {
        Header: Header,
    },
    data() {
        return {
            content: {},
            currentParentColId: 0,
        };
    },
    methods: {
        async getList() {
            await axios
                .get(`${api}/v1/article/company/info/1`, {
                    params: {
                        pi: 1,
                        ps: 20,
                    },
                })
                .then((res) => {
                    if (res.data) this.content = res.data;
                });
        },
    },
    mounted() {
        this.currentParentColId = this.$route.query.cpid; //父栏目id
        this.getList();
    },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>