<template>
  <div>
    <div class="header clear">
      <div class="box">
        <div class="logo">
          <h1>太原理工技术转移有限公司</h1>
          <a href="/">
            <img src="../assets/images/logo.png" />
          </a>
        </div>
        <div class="menu_page">
          <ul>
            <li
              class="main_page"
              v-for="item in colmunList"
              :key="item.id"
              @mouseover="isShowId = item.id"
              @mouseleave="isShowId = 0"
            >
              <a
                :href="item.href + '?cpid=' + item.id + '&clid=0'"
                class="main_a"
              >
                {{ item.name }}
              </a>
              <div v-show="isShowId == item.id">
                <a
                  :href="
                    item.href + '?cpid=' + item.id + '&clid=' + childItem.id
                  "
                  v-for="childItem in item.child"
                  :key="childItem.id"
                >
                  {{ childItem.title }}</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--Banner-->
    <div class="swiper-container swiper1" id="index-swiper1">
      <div class="swiper-wrapper">
        <div class="swiper-slide index0"></div>
        <div class="swiper-slide index1"></div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>

    <div class="wrap c1">
      <div class="topNews">
        <!-- left -->
        <div class="swiper-container left swiper2" id="index-swiper2">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in imgList"
              :key="index"
            >
              <div @click="goAricle(item, colmunList[2].id)">
                <img :src="imgURL + item.coverImg" />
                <div class="mask"></div>
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>

        <!-- center  新闻资讯 -->
        <div class="newContent left clear">
          <div class="base_link clear" id="base_link">
            <a
              href="javascript:;"
              data-index="0"
              :class="newTabs == 1 ? 'on' : ''"
              @click="newTab(1, colmunList[2].child[0].id)"
              >{{ colmunList[2].child[0].title }}</a
            >
            <a
              href="javascript:;"
              data-index="1"
              :class="newTabs == 2 ? 'on' : ''"
              @click="newTab(2, colmunList[2].child[1].id)"
              >{{ colmunList[2].child[1].title }}</a
            >
            <span @click="add2">查看详情>></span>
          </div>
          <div class="" id="filess">
            <div class="cont_box" data-index="0" style="display: block">
              <div
                class="newsList"
                v-for="(item, index) in newList"
                :key="index"
              >
                <div class="newTitle" @click="goAricle(item, colmunList[2].id)">
                  <p>{{ item.title }}</p>
                  <span class="time">{{
                    item.pubdate | dateTimeFormatter
                  }}</span>
                </div>
                <div class="newCon" @click="goAricle(item, colmunList[2].id)">
                  <p>
                    {{ item.summary }}
                  </p>
                  <!-- <font>{{ item.pubdate | dateTimeFormatter }}</font> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightTools right">
          <div @click="add">
            <i class="icon1"></i>
            <p>需求发布</p>
          </div>
          <div @click="goRightsProtection">
            <i class="icon2"></i>
            <p>维权援助</p>
          </div>
          <div @click="handle">
            <i class="icon3"></i>
            <p>下载中心</p>
          </div>
        </div>
      </div>
      <div class="centerNews">
        <!-- 技术服务 -->
        <div class="newsContent left wow bounceInLeft animated">
          <div class="title clear">
            <div class="h3 left">技术服务</div>
            <div class="more right" @click="add4">更多业务>></div>
          </div>
          <!-- left -->
          <div class="swiper-container left swiper3" id="index-swiper3">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in colmunList[7].child"
                :key="index"
              >
                <div class="busCooperateList" @click="goServicessPage(item.id)">
                  <div class="left2 left">
                    <div>
                      <font>{{ item.title }}</font>
                    </div>
                    <a href="javascript:void(0)">了解详情></a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <!-- 科技成果展示  -->
        <div class="newsContent right wow bounceInRight animated">
          <div class="title clear">
            <div class="h3 left">成果展示</div>
            <div class="more right" @click="add6">查看更多>></div>
          </div>
          <!-- 成果展示 -->
          <div
            class="achievements"
            v-for="(item, index) in resultList"
            :key="index"
          >
            <!-- <div class="leftImg" @click="goAricle(resultList[0],colmunList[3].id)" v-if="resultList[0].coverImg">
                            <img :src="imgURL + resultList[0].coverImg" />
                        </div> -->
            <div class="rightText" @click="goAricle(item, colmunList[3].id)">
              <p>{{ item.title }}</p>
              <div class="detilsText">
                {{ item.summary }}
                <a href="javascript:;">[详情]</a>
              </div>
            </div>
          </div>
          <!-- <div v-if="resultList.length > 0">
                        <div class="achievementsList">
                            <a v-for="(item, index) in resultList.slice(1)" :key="index" href="javascript:;" @click="goAricle(item,colmunList[3].id)">
                                <font>{{ item.title }}</font><i>{{ item.create_time | dateTimeFormatter }}</i>
                            </a>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
    <!-- 推介 -->
    <div class="wrap">
      <div class="recommend">
        <!-- 专家推介 -->
        <div class="recommendLeft wow bounceInLeft animated" @click="add5()">
          <div class="title clear">
            <div class="h3">专家推介</div>
            <div class="name">Expert recommendation</div>
            <div class="more" @click="add9">查看更多>></div>
          </div>
          <div class="recommedCard clear">
            <div
              class="leftImg"
              v-if="expertList.length > 0 && expertList[0].headImg"
            >
              <img :src="imgURL + expertList[0].headImg" />
            </div>
            <div class="leftImg" v-else>
              <img src="../assets/images/expert.jpg" />
            </div>
            <div class="leftText">
              <font
                >专家：{{
                  expertList.length > 0 ? expertList[0].name : ""
                }}</font
              >
              <p>
                {{ expertList.length > 0 ? expertList[0].summary : "" }}
              </p>
            </div>
          </div>
        </div>
        <!-- 项目推介 -->
        <div class="projectRight">
          <div class="title clear">
            <div class="h3">项目推介</div>
            <div class="name">Project promotion</div>
            <div class="more" @click="add3">查看更多>></div>
          </div>
          <div class="projectItem">
            <!-- <div class="projectList wow fadeInUpBig animated" data-wow-delay=".2s">
                            <p>{{ item.title }}</p>
                            <div class="tag">
                                <span>{{ item.className }}</span>
                            </div>
                            <font>{{ item.summary }}</font>
                            <a href="javascript:;">查看详情</a>
                        </div> -->

            <!-- 循环 -->
            <div class="swiper-container" id="index-swiper4">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in projectList"
                  :key="index"
                  @click="goAricle(item, colmunList[5].id)"
                >
                  <div class="projectList" v-if="item.coverImg">
                    <div class="img">
                      <img :src="imgURL + item.coverImg" />
                    </div>
                    <div class="con">
                      <p>{{ item.title }}</p>
                      <div class="tag">
                        <span>{{ item.className }}</span>
                      </div>
                      <font>{{ item.summary }}</font>
                      <a href="javascript:;">查看详情</a>
                    </div>
                  </div>
                  <div class="projectList" v-else>
                    <div class="con1">
                      <p>{{ item.title }}</p>
                      <div class="tag">
                        <span>{{ item.className }}</span>
                      </div>
                      <font>{{ item.summary }}</font>
                      <a href="javascript:;">查看详情</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Swiper from "swiper";
import axios from "axios";
import api from "../url/url";
import "swiper/dist/css/swiper.min.css";
export default {
  name: "indexA",
  data() {
    return {
      swiper1: "",
      swiper2: "",
      swiper3: "",
      swiper4: "",
      isShowId: 0,
      imgURL: api + "/assets/", //图片地址
      colmunList: [
        { id: 0, name: "首页", href: "/" },
        { id: 1, name: "公司简介", href: "intro" },
        {
          id: 2,
          name: "新闻资讯",
          href: "newss",
          child: [{ title: "" }, { title: "" }],
        },
        { id: 3, name: "科技成果", href: "techAchievee" },
        { id: 4, name: "在孵企业", href: "enterprisess" },
        { id: 5, name: "重点项目推荐", href: "keyProjectt" },
        { id: 6, name: "专家库", href: "expertt" },
        {
          id: 7,
          name: "技术服务",
          href: "servicess",
          child: [],
        },
        { id: 8, name: "下载中心", href: "downn" },
      ],
      newList: [],
      newTabs: 1,
      imgList: [],
      resultList: [],
      projectList: [],
      expertList: [{ headImg: "" }],
    };
  },
  methods: {
    goServicessPage(clid) {
      this.$router.push({
        name: "servicess",
        query: {
          cpid: 7,
          clid,
        },
      });
    },

    init() {
      axios
        .get(`${api}/v1/columns/parent/2`, {
          params: {
            pi: 1,
            ps: 4,
          },
        })
        .then((res) => {
          this.colmunList[2].child = res.data.list;
          this.getNews(this.colmunList[2].child[0].id);
          this.getSwiper(this.colmunList[2].child[0].id);
        });
      axios
        .get(`${api}/v1/columns/parent/7`, {
          params: {
            pi: 1,
            ps: 200,
          },
        })
        .then((res) => {
          this.colmunList[7].child = res.data.list;
          this.$nextTick(() => {
            // 滚动条
            this.swiper3 = new Swiper("#index-swiper3", {
              direction: "vertical",
              slidesPerView: 4,
              spaceBetween: 0,
              slidesPerGroup: 1,
              loop: true,
              autoplay: 2000,
              pagination: {
                el: ".swiper-pagination",
                bullets: true,
              },
            });
          });
        });
    },

    handle() {
      this.$router.push({
        name: "downn",
        query: {
          cpid: 8,
          clid: 0,
        },
      });
    },
    goRightsProtection() {
      this.$router.push("/mendd");
    },
    add() {
      this.$router.push("/request");
    },
    add2() {
      this.$router.push({
        name: "newss",
        query: {
          cpid: 2,
          clid: 0,
        },
      });
    },
    add3() {
      this.$router.push({
        name: "keyProjectt",
        query: {
          cpid: 5,
          clid: 0,
        },
      });
    },
    add4() {
      this.$router.push({
        name: "servicess",
        query: {
          cpid: 7,
          clid: 0,
        },
      });
    },
    add5() {
      if (this.expertList.length > 0) {
        this.$router.push({
          name: "expertPagee",
          query: {
            id: this.expertList[0].id,
          },
        });
      }
    },
    add6() {
      this.$router.push({
        name: "techAchievee",
        query: {
          cpid: 3,
          clid: 0,
        },
      });
    },
    add9() {
      this.$router.push({
        name: "expertt",
        query: {
          cpid: 6,
          clid: 0,
        },
      });
    },
    add7() {
      this.hide = true;
    },
    add8() {
      this.hide = false;
    },
    //获取新闻
    getNews(id) {
      axios
        .get(`${api}/v1/article/index/news/${id}`, {
          params: {
            ps: 4,
          },
        })
        .then((res) => {
          this.newList = res.data.list;
        });
    },
    //获取项目推荐
    getProject() {
      axios
        .get(`${api}/v1/article/index/project/5`, {
          params: {
            ps: 10,
          },
        })
        .then((res) => {
          this.projectList = res.data.list;
          this.projectList.forEach((item) => {
            let str = item.coverImg.split("/");
            item.coverImg =
              str[0] + "/" + str[1] + "/" + str[2] + "/" + "_300_" + str[3];
          });
          this.$nextTick(() => {
            this.swiper4 = new Swiper("#index-swiper4", {
              slidesPerView: this.projectList.length > 1 ? 2 : 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              speed: 4000,
              loop: this.projectList.length > 2,
              autoplay: true,
              pagination: {
                el: ".swiper-pagination",
                bullets: true,
              },
            });
          });
        });
    },
    //获取成果展示
    getResult() {
      axios
        .get(`${api}/v1/article/index/results/3`, {
          params: {
            pi: 1,
            ps: 4,
          },
        })
        .then((res) => {
          this.resultList = res.data.list;
        });
    },
    //获取专家
    getExpert() {
      axios
        .get(`${api}/v1/professor`, {
          params: {
            pi: 1,
            ps: 1,
          },
        })
        .then((res) => {
          this.expertList = res.data.list;
        });
    },
    //获取轮播图
    getSwiper(id) {
      axios
        .get(`${api}/v1/article/index/imagenews/${id}`, {
          params: {
            ps: 4,
          },
        })
        .then((res) => {
          this.imgList = res.data.list;
          this.imgList.forEach((item) => {
            let str = item.coverImg.split("/");
            item.coverImg =
              str[0] + "/" + str[1] + "/" + str[2] + "/" + "_800_" + str[3];
          });
          this.$nextTick(() => {
            this.runswiper();
          });
        });
    },

    newTab(index, id) {
      this.newTabs = index;
      this.getNews(id);
    },
    runswiper() {
      this.swiper2 = new Swiper("#index-swiper2", {
        // loop: true, // 循环模式选项
        effect: "fade",
        watchSlidesProgress: true,
        centeredSlides: true,
        loop: false,
        autoplay: 4000,
        disableOnInteraction: false,
        // autoplayDisableOnInteraction: true, //触碰图片后时候轮播
        pagination: ".swiper-pagination",
        bullets: true,
      });
    },

    goAricle(item, columnId) {
      if (columnId == 3) {
        this.$router.push({
          name: "newsPagee",
          query: {
            id: item.id,
            columnId: columnId,
            routeName: "index",
          },
        });
      } else {
        this.$router.push({
          name: "newsPagee",
          query: {
            id: item.id,
            columnId: columnId,
          },
        });
      }
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.swiper1 = new Swiper("#index-swiper1", {
      //   loop: true,
      effect: "fade",
      watchSlidesProgress: true,
      centeredSlides: true,
      loop: true,
      autoplay: 6000,
      disableOnInteraction: false,
      // autoplayDisableOnInteraction: true, //触碰图片后时候轮播
      pagination: ".swiper-pagination",
      bullets: true,
    });

    this.init();
    this.getResult();
    this.getProject();
    this.getExpert();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "~@/assets/common.css";
@import "~@/assets/index.scss";
.swiper3 {
  height: 405px;
  margin-top: 10px;
  .swiper-slide {
    height: 80px !important;
    margin-bottom: 20px;
  }
}
.con1 {
  float: left;
  width: 230px;

  p {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 13em;
  }

  .tag {
    margin: 10px 0;

    span {
      display: inline-block;
      background: #e5ebf3;
      color: #5f81b4;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 2px;
      margin-right: 5px;
      font-weight: 700;
      cursor: pointer;
    }
  }

  font {
    font-size: 14px;
    color: #707070;
    line-height: 25px;
    display: -webkit-box;
    height: 50px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
  }

  a {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #343434;
    margin-top: 15px;
    margin-left: 150px;
  }
}
</style>
  