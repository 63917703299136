<template>
    <div>
        <Header :curColid="currentParentColId"></Header>
        <div>
            <div class="wrap layout">
                <div class="pageDiv">
                    <div class="page">
                        <span @click="goindex">首页></span>
                        <span>专家库</span>
                    </div>
                </div>
                <div class="pageContent clear">
                    <div class="search-box">
                        <input type="text" id="keyword" v-model="searchName" maxlength="255" placeholder="请输入姓名搜索" />
                        <button id="search" @click="search">搜索</button>
                    </div>
                    <div class="screen">
                        <div class="title">专家擅长领域筛选</div>
                        <div class="screenItem">
                            <h3>行业领域：</h3>
                            <div class="screenList">
                                <a v-for="(item, index) in areaList" :key="index" href="javascript:;" :class="item.show ? 'on' : ''" @click="areaSelect(item, index)">{{ item.name }}</a>
                            </div>
                        </div>
                        <div class="screenItem">
                            <h3>职称：</h3>
                            <div class="screenList">
                                <a v-for="(item, index) in postionList" :key="index" href="javascript:;" :class="item.show ? 'on' : ''" @click="postionSelect(item, index)">{{ item.name }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="projectItem">
                        <div class="projectDiv">
                            <div class="recommedList wow fadeInUpBig animated" data-wow-delay=".2s" @click="goExpertPage(item)" v-for="(item, index) in expertList" :key="index">
                                <div class="contenTop">
                                    <div class="leftImg" v-if="item.headImg">
                                        <img :src="imgURL + item.headImg" />
                                    </div>
                                    <div class="leftImg" v-else>
                                        <img src="../assets/images/expert.jpg" />
                                    </div>
                                    <div class="leftText">
                                        <h3>{{ item.name }}</h3>
                                        <div class="tag">
                                            <span>{{ item.goodAt[0].tagName }}</span><span v-if="item.goodAt.length > 1">{{
                          item.goodAt[1].tagName
                        }}</span>
                                        </div>
                                        <span>职称：{{ item.position[0].tagName }}</span>
                                    </div>
                                </div>

                                <div class="contenText">
                                    <p>简介：{{ item.summary }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style="width: 1200px; margin: 30px auto">
                    <el-pagination layout="total,prev, pager, next" @current-change="handleCurrentChange" :page-size="20" :total="total" :current-page="page" style="float: right"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
import axios from "axios";
import api from "../url/url";
import Header from "../components/Header.vue";

export default {
    name: "zjkA",
    components: {
        Header: Header,
    },
    data() {
        return {
            searchName: "",
            imgURL: api + "/assets/", //图片地址
            columnsName: "",
            columns: [],
            currentColId: 0,
            currentParentColId: 0,
            articles: [],
            content: {},
            areaList: [],
            postionList: [],
            expertList: [],
            total: 0,
            page: 1,
            tags: [],
            name: "",
        };
    },

    methods: {
        goindex() {
            this.$router.push("/");
        },
        goExpertPage(item) {
            this.$router.push({
                name: "expertPagee",
                query: {
                    id: item.id,
                },
            });
        },
        onclick(id, columnsName) {
            this.columnsName = columnsName;
            this.currentColId = id;
            this.getArticles();
        },
        getColumns() {
            axios
                .get(`${api}/v1/columns/parent/${this.currentParentColId}`, {
                    params: {
                        pi: this.page,
                        ps: 20,
                    },
                })
                .then((res) => {
                    this.columns = res.data.list;
                    if (this.columns.length > 0) {
                        if (this.currentColId == 0) {
                            //默认
                            this.columnsName = this.columns[0].title;
                            this.currentColId = this.columns[0].id;
                        } else {
                            //指定栏目
                            this.columnsName = this.columns.filter(
                                (x) => x.id == this.currentColId
                            )[0].title;
                        }
                        this.getArticles();
                    }
                });
        },

        getArticles() {
            axios
                .get(`${api}/v1/article/${this.currentColId}/0`, {
                    params: {
                        pi: this.page,
                        ps: 20,
                    },
                })
                .then((res) => {
                    this.content = res.data.list[0];
                    this.total = res.data.total;
                    this.articles = res.data.list;
                });
        },
        //获取行业领域
        getArea() {
            axios.get(`${api}/v1/tag/1`).then((res) => {
                res.data.forEach((item) => {
                    item.show = false;
                });

                this.areaList = [
                    { name: "全部", show: true, type: 0 },
                    ...res.data,
                ];

                this.getPostion();
            });
        },
        //获取职称列表
        getPostion() {
            axios.get(`${api}/v1/tag/2`).then((res) => {
                this.postionList = [
                    { name: "全部", show: true, type: 0 },
                    ...res.data,
                ];
                let arr = [];
                this.areaList
                    .filter((x) => x.type != 0)
                    .forEach((item) => {
                        arr.push(item.id);
                    });
                this.postionList
                    .filter((x) => x.type != 0)
                    .forEach((item) => {
                        arr.push(item.id);
                    });
                this.tags = arr;
                if (this.tags.length > 0) {
                    this.getExpert();
                }
            });
        },
        //获取专家
        getExpert() {
            axios
                .post(`${api}/v1/professor/search?pi=${this.page}&ps=${20}`, {
                    tags: this.tags,
                    name: this.name,
                })
                .then((res) => {
                    this.expertList = res.data.list;
                    this.total = res.data.total;
                });
        },
        postionSelect(item) {
            this.searchName = "";
            let arr = [];
            if (item.type == 0) {
                this.$set(item, "show", true);
                this.postionList
                    .filter((x) => x.type != 0)
                    .forEach((item1) => {
                        this.$set(item1, "show", false);
                        arr.push(item1.id);
                    });
                if (this.areaList[0].show) {
                    this.areaList
                        .filter((x) => x.type != 0)
                        .forEach((item2) => {
                            arr.push(item2.id);
                        });
                } else {
                    this.areaList
                        .filter((x) => x.type != 0)
                        .forEach((item3) => {
                            if (item3.show) {
                                arr.push(item3.id);
                            }
                        });
                }
            } else {
                this.$set(this.postionList[0], "show", false);
                if (item.show) {
                    this.$set(item, "show", false);
                } else {
                    this.$set(item, "show", true);
                }
                this.postionList
                    .filter((x) => x.type != 0)
                    .forEach((item1) => {
                        if (item1.show) {
                            arr.push(item1.id);
                        }
                    });
                if (this.areaList[0].show) {
                    this.areaList
                        .filter((x) => x.type != 0)
                        .forEach((item2) => {
                            arr.push(item2.id);
                        });
                } else {
                    this.areaList
                        .filter((x) => x.type != 0)
                        .forEach((item2) => {
                            if (item2.show) {
                                arr.push(item2.id);
                            }
                        });
                }
            }
            this.tags = arr;
            this.getExpert();
        },
        areaSelect(item) {
            this.searchName = "";
            let arr = [];
            if (item.type == 0) {
                this.$set(item, "show", true);
                this.areaList
                    .filter((x) => x.type != 0)
                    .forEach((item1) => {
                        this.$set(item1, "show", false);
                        arr.push(item1.id);
                    });
                if (this.postionList[0].show) {
                    this.postionList
                        .filter((x) => x.type != 0)
                        .forEach((item2) => {
                            arr.push(item2.id);
                        });
                } else {
                    this.postionList
                        .filter((x) => x.type != 0)
                        .forEach((item3) => {
                            if (item3.show) {
                                arr.push(item3.id);
                            }
                        });
                }
            } else {
                this.$set(this.areaList[0], "show", false);
                if (item.show) {
                    this.$set(item, "show", false);
                } else {
                    this.$set(item, "show", true);
                }
                this.areaList
                    .filter((x) => x.type != 0)
                    .forEach((item1) => {
                        if (item1.show) {
                            arr.push(item1.id);
                        }
                    });
                if (this.postionList[0].show) {
                    this.postionList
                        .filter((x) => x.type != 0)
                        .forEach((item2) => {
                            arr.push(item2.id);
                        });
                } else {
                    this.postionList
                        .filter((x) => x.type != 0)
                        .forEach((item2) => {
                            if (item2.show) {
                                arr.push(item2.id);
                            }
                        });
                }
            }
            this.tags = arr;
            this.getExpert();
        },

        searchNameList() {
            axios
                .get(`${api}/v1/professor/search/name?name=${this.searchName}`)
                .then((res) => {
                    this.expertList = res.data.list;
                    this.total = res.data.list.length;
                });
        },
        search() {
            if (this.searchName) {
                this.areaList.forEach((item, index) => {
                    if (index == 0) {
                        item.show = true;
                    } else {
                        item.show = false;
                    }
                });
                this.postionList.forEach((item, index) => {
                    if (index == 0) {
                        item.show = true;
                    } else {
                        item.show = false;
                    }
                });
                this.searchNameList();
            }
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getExpert();
        },
    },

    mounted() {
        this.currentParentColId = this.$route.query.cpid; //父栏目id
        this.currentColId = this.$route.query.clid; //子栏目id
        this.getColumns();
        this.getArea();
    },
};
</script>
  <style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>