<template>
    <div>
        <router-view></router-view>
        <WrapFooter></WrapFooter>
    </div>
</template>

<script>
import WrapFooter from "./components/WrapFooter.vue";

export default {
    components: {
        WrapFooter: WrapFooter,
    },
};
</script>

<style>
</style>