<template>
    <div>
        <Header></Header>
        <div class="wrap layout">
            <div class="pageDiv">
                <div class="page">
                    <span>首页></span>
                    <span>重点项目></span>
                    <span>详情</span>
                </div>
            </div>
            <div class="pageContent clear">
                <div class="zdxmPage">
                    <div class="title">水性高分子涂料</div>
                    <div class="tag">
                        <span>高端化工</span>
                    </div>
                    <div class="pageList">
                        <span>需求类型：<font>成果转让/技术服务</font></span>
                        <span>发布时间：<font>2023-08-01</font></span>
                        <span>成熟度：<font>已有样品</font></span>
                    </div>
                    <button class="button">在线联系</button>
                    <div class="line"></div>
                    <div class="text">
                        <div class="title2"><i></i>成果名称</div>
                        <div>
                            <p>水性高分子涂料</p>
                        </div>
                    </div>
                    <div class="text">
                        <div class="title2"><i></i>成果简介</div>
                        <div>
                            <p>
                                水性聚氨酯以水为分散剂 , 具有无 VOC
                                排放、安全可靠、相容性好、易于改性以及成本低廉等优点。
                            </p>
                            <p>
                                本涂料细度 :15 35μm; 表干 1.5 h, 实干 5 h; 耐冲击 50 mm; 附着力
                                :0 级 ;弯曲性 :2 mm; 光泽 :87,
                            </p>
                            <p>
                                硬度 :H; 耐盐雾 :1500 h; 耐人工气候老化 : 失光 1 级、变色 1 级
                                。
                            </p>

                            <p>成果形式</p>
                            <p>以产品为载体通过实际应用。</p>

                            <p>成果领域</p>
                            <p>
                                应用于防腐涂料、胶粘剂、织物涂层整理剂、皮革涂饰剂、纸张表面处理剂和纤维表面处理剂等领域。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header.vue";

export default {
    components: {
        Header: Header,
    },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>