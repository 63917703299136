<template>
  <div>
    <Header :curColid="currentParentColId"></Header>
    <div>
      <div class="wrap layout">
        <div class="pageDiv">
          <div class="page">
            <span @click="goindex">首页></span>
            <span>技术服务</span>
          </div>
        </div>
        <div class="pageContent clear">
          <div class="left">
            <div
              class="list3"
              v-for="item in columns"
              :key="item.id"
              @click="onclick(item.id, item.title)"
            >
              <a :class="currentColId == item.id ? 'on' : ''">{{
                item.title
              }}</a>
            </div>
          </div>
          <div class="right" v-if="isContentShow">
            <div class="editContent">
              <div class="editTitle">{{ content.title }}</div>
              <!-- <div class="date">
                                <span>{{ content.source }}</span>
                                <span> {{ content.create_time|dateTimeFormatter }}</span>
                            </div> -->
              <div class="desc" v-if="content.summary">
                {{ content.summary }}
              </div>
              <div class="editText" v-html="content.content"></div>
            </div>
          </div>
          <div class="right" v-else>
            <!-- <h3>{{ columnsName }}</h3> -->
            <div class="rightTop">
              <h3>{{ columnsName }}</h3>
            </div>
            <div class="list">
              <div
                v-for="item in articles"
                :key="item.id"
                @click="goNewsPage(item.id)"
              >
                <a>
                  <p>{{ item.title }}</p>
                  <font>{{ item.summary }}</font>
                </a>
              </div>
            </div>
            <div>
              <el-pagination
                layout="total,prev, pager, next"
                @current-change="handleCurrentChange"
                :page-size="20"
                :total="total"
                :current-page="page"
                style="float: right"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "../url/url";
import Header from "../components/Header.vue";

export default {
  name: "jsfwA",
  components: {
    Header: Header,
  },
  data() {
    return {
      isContentShow: false,
      columnsName: "",
      columns: [],
      currentColId: 0,
      currentParentColId: 0,
      articles: [],
      content: {},
      total: 0,
      page: 1,
    };
  },
  methods: {
    goindex() {
      this.$router.push("/");
    },
    goNewsPage(id) {
      axios.get(`${api}/v1/article/${id}`).then((res) => {
        this.isContentShow = true;
        this.content = res.data;
      });
    },
    onclick(id, columnsName) {
      this.columnsName = columnsName;
      this.currentColId = id;
      this.content = {};
      this.getArticles();
    },
    getColumns() {
      axios
        .get(`${api}/v1/columns/parent/${this.currentParentColId}`, {
          params: {
            pi: this.page,
            ps: 20,
          },
        })
        .then((res) => {
          this.columns = res.data.list;
          if (this.columns.length > 0) {
            if (this.currentColId == 0) {
              //默认
              this.columnsName = this.columns[0].title;
              this.currentColId = this.columns[0].id;
            } else {
              //指定栏目
              this.columnsName = this.columns.filter(
                (x) => x.id == this.currentColId
              )[0].title;
            }
            this.getArticles();
          }
        });
    },

    getArticles() {
      axios
        .get(`${api}/v1/article/${this.currentColId}/0`, {
          params: {
            pi: this.page,
            ps: 20,
          },
        })
        .then((res) => {
          this.total = res.data.total;
          this.articles = res.data.list;
          if (
            this.currentColId == 46 ||
            this.currentColId == 45 ||
            this.currentColId == 44 ||
            this.currentColId == 43 ||
            this.currentColId == 41 ||
            this.currentColId == 42
          ) {
            this.isContentShow = true;
            if (this.articles.length > 0) this.goNewsPage(this.articles[0].id);
          } else {
            this.isContentShow = false;
          }
        });
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getArticles();
    },
  },
  mounted() {
    this.currentParentColId = this.$route.query.cpid; //父栏目id
    this.currentColId = this.$route.query.clid; //子栏目id
    this.getColumns();
  },
};
</script>

<style  lang="scss" scoped>
@import "~@/assets/page.scss";
</style>