
/*
 * 全局的过滤函数
 * */
function checkAddZone(num) {
  return num < 10 ? `0${num.toString()}` : num
}

const globalFilter = {
 
  dateTimeFormatter(t) {
    if (!t) return ''
    t = new Date(t).getTime() // eslint-disable-line
    t = new Date(t) // eslint-disable-line
    const year = t.getFullYear()
    let month = t.getMonth() + 1
    month = checkAddZone(month)

    let date = t.getDate()
    date = checkAddZone(date)

    // let hour = t.getHours()
    // hour = checkAddZone(hour)

    // let min = t.getMinutes()
    // min = checkAddZone(min)

    // let se = t.getSeconds()
    // se = checkAddZone(se)

    return `${year}-${month}-${date} `
  },

 
}
export default globalFilter
