import about from '../views/about'
import news from '../views/news'
import techAchieve from '../views/techAchieve'
import enterprises from '../views/enterprises'
import keyProject from '../views/keyProject'
import expert from '../views/expert'
import services from '../views/services'
import down from '../views/down'
import index from '../views/index'
import newsPage from '../views/newsPage'
import Vue from 'vue'
import VueRouter from 'vue-router'
import keyProjectPage from '../views/keyProjectPage'
import mend from '../views/mend'
import Request from '../views/request'
import expertPage from '../views/expertPage'
// import zjkPage from '../views/zjkPage'
Vue.use(VueRouter)
const router = new VueRouter({
    mode:'history',
    routes: [
        { path: '/', component: () => import('../views/index.vue') },

        { name:'intro',path: '/intro', component: about },
        { name:'newss',path: '/newss', component: news },
        { name:'techAchievee',path: '/techAchievee', component: techAchieve },
        { name:'enterprisess',path: '/enterprisess', component: enterprises },
        { name:'keyProjectt',path: '/keyProjectt', component: keyProject },
        { name:'expertt',path: '/expertt', component: expert },
        { name:'servicess',path: '/servicess', component: services },
        { name:'downn',path: '/downn', component: down },
        { name:'indexx',path: '/indexx', component: index },
        { name:'newsPagee',path: '/newsPagee', component: newsPage },
        { name:'keyProjectPagee',path: '/keyProjectPagee', component: keyProjectPage },
        { name:'mendd',path: '/mendd', component: mend },
        { name:'expertPagee',path: '/expertPagee', component: expertPage },
        { name: 'request', path: '/request', component: Request }
        // { path: '/zjkPagee', component: zjkPage },





    ]
})

export default router